<template>
	<div>
		<Header :title="isEdit ? '修改优惠券政策' : '新增优惠券政策'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" name="form" :labelCol="{span: 6, xxl: 5}"
				:wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

				<a-form-item name="title" label="政策标题" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.title" :disabled="isSee" placeholder="请输入政策标题"></a-input>
				</a-form-item>

				<a-form-item label="政策类型" name="useLimit" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.useLimit" button-style @change="onTypeChange" :disabled="modelRef.id">
						<a-radio :value="1">影片</a-radio>
						<a-radio :value="2">演出</a-radio>
						<a-radio :value="3">卖品</a-radio>
						<a-radio :value="4">商城</a-radio>
					</a-radio-group>
				</a-form-item>

				<div v-if="modelRef.useLimit === 1">
					<!-- 影片 -->
					<a-form-item label="影片规则" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div class="ui-flex">
							<a-radio-group :disabled="isSee" v-model:value="modelRef.filmType" button-style="solid"
								@change="modelRef.filmList = [];movieList = [];">
								<a-radio-button :value="1">不限制</a-radio-button>
								<a-radio-button :value="2">指定可用影片</a-radio-button>
								<a-radio-button :value="3">指定不可用影片</a-radio-button>
							</a-radio-group>
							<span v-if="modelRef.filmType !== 1" style="margin-left: 20px;">
								<movie :disabled="isSee" v-model:value="modelRef.filmList"
									@change="(dataList) => { movieList = dataList; }"></movie>
							</span>
						</div>
						<div v-if="movieList.length" style="margin: 10px 0;">
							<a-table rowKey="id" :columns="movieColumns" :dataSource="movieList" size="small"
								:pagination="false">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'action'">
										<a-button :disabled="isSee" type="link"
											@click="onDeleteMovie(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>

					<a-form-item label="影厅规则" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div class="ui-flex">
							<a-radio-group :disabled="isSee" v-model:value="modelRef.hallType" button-style="solid"
								@change="modelRef.hallList = [];hallDataList = [];">
								<a-radio-button :value="1">不限制</a-radio-button>
								<a-radio-button :value="2">指定可用影厅</a-radio-button>
								<a-radio-button :value="3">指定不可用影厅</a-radio-button>
							</a-radio-group>
							<span v-if="modelRef.hallType !== 1" style="margin-left: 20px;">
								<cinema :disabled="isSee" v-model:value="modelRef.hallList"
									@change="(dataList) => {hallDataList = dataList;}"></cinema>
							</span>
						</div>
						<div v-if="hallDataList.length" style="margin: 10px 0;">
							<a-table rowKey="id" :columns="hallColumns" :dataSource="hallDataList" size="small"
								:pagination="false">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'action'">
										<a-button :disabled="isSee" type="link"
											@click="onDeleteHall(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>

					<a-form-item label="视觉规则" name="visualType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.visualType" button-style="solid">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2">指定可用视觉</a-radio-button>
							<a-radio-button :value="3">指定不可用视觉</a-radio-button>
						</a-radio-group>
					</a-form-item>

					<a-form-item v-if="modelRef.visualType !== 1" label="选择视觉" name="dimensionalList"
						:rules="[{required: true, message: '必选项不允许为空'}]">
						<a-form-item-rest>
							<a-checkbox :disabled="isSee" v-model:checked="checkAll" :indeterminate="indeterminate"
								@change="onCheckAllChange">全选</a-checkbox>
						</a-form-item-rest>
						<a-checkbox-group :disabled="isSee" v-model:value="modelRef.dimensionalList">
							<a-checkbox :value="1">2D</a-checkbox>
							<a-checkbox :value="2">3D</a-checkbox>
							<a-checkbox :value="3">4D</a-checkbox>
							<a-checkbox :value="4">IMAX2D</a-checkbox>
							<a-checkbox :value="5">IMAX3D</a-checkbox>
						</a-checkbox-group>
					</a-form-item>

					<a-form-item label="分区规则" name="partitionType" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.partitionType" button-style="solid">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2">指定可用分区</a-radio-button>
							<a-radio-button :value="3">指定不可用分区</a-radio-button>
						</a-radio-group>
					</a-form-item>
					<a-form-item v-if="modelRef.partitionType !== 1" label="选择分区" name="partitionList"
						:rules="[{required: true, message: '必选项不允许为空'}]">
						<a-form-item-rest>
							<a-checkbox :disabled="isSee" v-model:checked="partitionCheckAll"
								:indeterminate="indeterminatePartition"
								@change="onPartitionCheckAllChange">全选</a-checkbox>
						</a-form-item-rest>
						<a-checkbox-group :disabled="isSee" v-model:value="modelRef.partitionList">
							<a-checkbox v-for="item in partitionAllList" :key="item.id"
								:value="item.id">{{ item.name }}</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
				</div>

				<div v-if="modelRef.useLimit === 2">
					<!-- 演出-->
					<a-form-item name="performType" label="演出规则" :rules="{ required: true, message: '必选项不允许为空' }">
						<div class="ui-flex">
							<a-radio-group :disabled="isSee" v-model:value="modelRef.performType" button-style="solid" @change="modelRef.performList = [];performDataList = [];">
								<a-radio-button :value="1">不限制</a-radio-button>
								<a-radio-button :value="2">指定可用演出</a-radio-button>
								<a-radio-button :value="3">指定不可用演出</a-radio-button>
							</a-radio-group>
							<a-form-item-rest>
								<span v-if="modelRef.performType !== 1" style="margin-left: 20px;">
									<perform :disabled="isSee" v-model:value="modelRef.performList" :selectItems="performDataList"
										@change="(dataList) => {performDataList = dataList;}"></perform>
								</span>
							</a-form-item-rest>
						</div>
						<div v-if="performDataList.length" style="margin-top: 10px;">
							<a-table :pagination="false" :columns="performColumns" :dataSource="performDataList">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'posterUrl'">
										<a-image :src="record.posterUrl" style="width: 70px;"></a-image>
									</template>
									<template v-if="column.key === 'action'">
										<a-button :disabled="isSee" type="link"
											@click="onDeletePerform(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>
				</div>
				<div v-if="modelRef.useLimit === 3">
					<!-- 卖品 -->
					<a-form-item name="snackType" label="卖品规则" :rules="{ required: true, message: '必选项不允许为空' }">
						<a-form-item-rest>
							<div class="ui-flex">
								<a-radio-group :disabled="isSee" v-model:value="modelRef.snackType" button-style="solid" @change="modelRef.snackList = [];snackDataList = [];">
									<a-radio-button :value="1">不限制</a-radio-button>
									<a-radio-button :value="2">指定可用卖品</a-radio-button>
									<a-radio-button :value="3">指定不可用卖品</a-radio-button>
								</a-radio-group>
								<span v-if="modelRef.snackType !== 1" style="margin-left: 20px;">
									<snack :disabled="isSee" v-model:value="modelRef.snackList"
										@change="(dataList) => {snackDataList = dataList;}"></snack>
								</span>
							</div>
							<div v-if="snackDataList.length" style="margin-top: 10px;">
								<a-table :pagination="false" :columns="snackColumns" :dataSource="snackDataList">
									<template #bodyCell="{ column, record, index }">
										<template v-if="column.key === 'action'">
											<a-button :disabled="isSee" type="link"
												@click="onDeleteSnack(record, index)">删除</a-button>
										</template>
									</template>
								</a-table>
							</div>
						</a-form-item-rest>
					</a-form-item>
				</div>
				<div v-if="modelRef.useLimit === 4">
					<!-- 商城 -->
					<a-form-item name="goodsType" label="商品规则" :rules="{ required: true, message: '必选项不允许为空' }">
						<a-form-item-rest>
							<div class="ui-flex">
								<a-radio-group :disabled="isSee" v-model:value="modelRef.goodsType" button-style="solid" @change="modelRef.goodsList = [];goodsDataList = [];">
									<a-radio-button :value="1">不限制</a-radio-button>
									<a-radio-button :value="2">指定可用商品</a-radio-button>
									<a-radio-button :value="3">指定不可用商品</a-radio-button>
								</a-radio-group>
								<span v-if="modelRef.goodsType !== 1" style="margin-left: 20px;">
									<goods :disabled="isSee" v-model:value="modelRef.goodsList"
										@change="(dataList) => {goodsDataList = dataList;}"></goods>
								</span>
							</div>
							<div v-if="goodsDataList.length" style="margin-top: 10px;">
								<a-table :pagination="false" :columns="goodsColumns" :dataSource="goodsDataList">
									<template #bodyCell="{ column, record, index }">
										<template v-if="column.key === 'action'">
											<a-button :disabled="isSee" type="link"
												@click="onDeleteGoods(record, index)">删除</a-button>
										</template>
									</template>
								</a-table>
							</div>
						</a-form-item-rest>
					</a-form-item>
				</div>

				<a-form-item label="可使用时间" v-show="modelRef.useLimit != 1">
					<div v-for="(record, recordIndex) in modelRef.timeList" :key="recordIndex">
						<div class="ui-time__header">
							<span class="ui-time__span">日期</span>
<!--							<a-date-picker :disabled="isSee" v-model:value="record.startTime" />
							<span class="ui-time__span">至</span>
							<a-date-picker :disabled="isSee" v-model:value="record.endTime" />-->
              <a-range-picker v-model:value="record.time"/>
							<a class="ui-time__span" @click="onAddSet(recordIndex)">
								<Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
							</a>
              <a class="ui-time__span" @click="onDelSetting(recordIndex)"  v-if="this.modelRef.timeList.length != 1">
                <Icon icon="DeleteOutlined" style="margin-right: 2px;"></Icon>删除该时间段
              </a>
						</div>
						<div class="ui-time__set" v-for="(item,index) in record.cycles" :key="index">
							<div>
								<span class="ui-time__span">周期</span>
								<a-checkbox-group :disabled="isSee" v-model:value="item.weeks">
									<a-checkbox :value="7">周日</a-checkbox>
									<a-checkbox :value="1">周一</a-checkbox>
									<a-checkbox :value="2">周二</a-checkbox>
									<a-checkbox :value="3">周三</a-checkbox>
									<a-checkbox :value="4">周四</a-checkbox>
									<a-checkbox :value="5">周五</a-checkbox>
									<a-checkbox :value="6">周六</a-checkbox>
								</a-checkbox-group>
							</div>
							<div class="ui-margin__top20">
								<span class="ui-time__span">时段</span>
                <a-time-range-picker v-model:value="item.time"/>
                <!--								<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.startTime" />-->
<!--								<span class="ui-time__span">至</span>-->
<!--								<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.endTime" />-->
                <a class="ui-time__span" @click="onAddSubSetting(recordIndex, index)">
                  <Icon icon="PlusCircleOutlined"></Icon> 添加
                </a>
								<a class="ui-time__span" @click="onDelSubSetting(recordIndex, index)" v-if="record.cycles && record.cycles.length != 1">
									<Icon icon="DeleteOutlined"></Icon> 删除
								</a>
							</div>
						</div>
					</div>
				</a-form-item>

        <a-form-item label="可用放映时间" v-show="modelRef.useLimit == 1">
          <div v-for="(record, recordIndex) in modelRef.showTimeList" :key="recordIndex">
            <div class="ui-time__header">
              <span class="ui-time__span">日期</span>
              <!--							<a-date-picker :disabled="isSee" v-model:value="record.startTime" />
                                          <span class="ui-time__span">至</span>
                                          <a-date-picker :disabled="isSee" v-model:value="record.endTime" />-->
              <a-range-picker v-model:value="record.time"/>
              <a class="ui-time__span" @click="onAddSetShowTime(recordIndex)">
                <Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
              </a>
              <a class="ui-time__span" @click="onDelSettingShowTime(recordIndex)"  v-if="this.modelRef.showTimeList.length != 1">
                <Icon icon="DeleteOutlined" style="margin-right: 2px;"></Icon>删除该时间段
              </a>
            </div>
            <div class="ui-time__set" v-for="(item,index) in record.cycles" :key="index">
              <div>
                <span class="ui-time__span">周期</span>
                <a-checkbox-group :disabled="isSee" v-model:value="item.weeks">
                  <a-checkbox :value="7">周日</a-checkbox>
                  <a-checkbox :value="1">周一</a-checkbox>
                  <a-checkbox :value="2">周二</a-checkbox>
                  <a-checkbox :value="3">周三</a-checkbox>
                  <a-checkbox :value="4">周四</a-checkbox>
                  <a-checkbox :value="5">周五</a-checkbox>
                  <a-checkbox :value="6">周六</a-checkbox>
                </a-checkbox-group>
              </div>
              <div class="ui-margin__top20">
                <span class="ui-time__span">时段</span>
                <a-time-range-picker v-model:value="item.time"/>
                <!--								<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.startTime" />-->
                <!--								<span class="ui-time__span">至</span>-->
                <!--								<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.endTime" />-->
                <a class="ui-time__span" @click="onAddSubSettingShowTime(recordIndex, index)">
                  <Icon icon="PlusCircleOutlined"></Icon> 添加
                </a>
                <a class="ui-time__span" @click="onDelSubSettingShowTime(recordIndex, index)" v-if="record.cycles && record.cycles.length != 1">
                  <Icon icon="DeleteOutlined"></Icon> 删除
                </a>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="注" style="color: red">
          <p style="margin-top: 5px">1.如果需要配置周期，必须配置日期，如果不配置，周期则会丢失；</p>
          <p>2.如果需要配置时段，必须配置日期与周期，如果不配置，时段周期则会丢失；</p>
          <p>3.不添加时间段默认所有时间可用；</p>
          <p>4.可用放映时间适用于政策类型为影片，可使用时间用适用于政策类型为演出、卖品、商城；</p>
        </a-form-item>
				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js'
	import movie from "@/components/movie/index";
	import cinema from '@/components/cinema/index';
	import Header from '@/components/header/header.vue';
	import perform from '@/components/select/selectPerform/index.vue';
	import snack from '@/components/select/selectSales/index.vue';
	import goods from '@/components/select/selectGoods/index.vue';
	import { getAllCinemaPartitionList } from '@/service/modules/cinema.js';
	import {
		saveRules,
		updateRules,
		getRulesDetail
	} from "@/service/modules/coupon.js";
	export default {
		components: {
			Icon,
			Header,
			movie,
			cinema,
			perform,
			snack,
			goods
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				indeterminate: false,
				indeterminatePartition: false,
				partitionAllList: [],
				modelRef: {
					useLimit: 1,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					visualType: 1,
					dimensionalList: [],
					partitionType: 1,
					partitionList: [],
					performType: 1,
					performList: [],
					snackType: 1,
					snackList: [],
					goodsType: 1,
					goodsList: [],
					timeList: [],
          showTimeList: [],
          title: '',
				},
				movieList: [],
				movieColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '影片名称',
					dataIndex: 'name'
				}, {
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				}, {
					title: '操作',
					key: 'action'
				}],
				hallDataList: [],
				hallColumns: [{
					title: '影厅编码',
					dataIndex: 'code'
				}, {
					title: '影厅名称',
					dataIndex: 'name'
				}, {
					title: '操作',
					key: 'action'
				}],
				performDataList: [],
				performColumns: [{
					title: '演出名称',
					dataIndex: 'name'
				}, {
					title: '演出海报',
					key: 'posterUrl'
				}, {
					title: '演出分类',
					dataIndex: 'classifyName'
				}, {
					title: '操作',
					key: 'action'
				}],
				snackDataList: [],
				snackColumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品分类',
					dataIndex: 'classifyName'
				}, {
					title: '操作',
					key: 'action'
				}],
				goodsDataList: [],
				goodsColumns: [{
					title: '商品品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '商品分类',
					dataIndex: 'classifyName'
				}, {
					title: '操作',
					key: 'action'
				}],
			}
		},
		watch: {
			'modelRef.dimensionalList'(newVal) {
				this.indeterminate = !!newVal.length && newVal.length < 5;
				this.checkAll = newVal.length === 5;
			},
			'modelRef.partitionList'(newVal) {
				this.indeterminatePartition = !!newVal.length && newVal.length < this.partitionAllList.length;
				this.partitionCheckAll = newVal.length === this.partitionAllList.length;
			},
		},
		created() {
      // console.log(this.moment.unix(8*3600).format('HH:mm'))
			this.getAllCinemaPartitionList();
			if (this.id) {
				this.getData();
			}
		},
    mounted() {
      this.modelRef.timeList.push({
        time: [],
        cycles: [
          {
            weeks: [],
            time: []
          }
        ]
      });
      this.modelRef.showTimeList.push({
        time: [],
        cycles: [
          {
            weeks: [],
            time: []
          }
        ]
      })
    },
    methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.filmList = postData.filmList.join(',');
				postData.hallList = postData.hallList.join(',');
				postData.dimensionalList = postData.dimensionalList.join(',');
				postData.partitionList = postData.partitionList.join(',');
				postData.performList = postData.performList.join(',');
				postData.snackList = postData.snackList.join(',');
				postData.goodsList = postData.goodsList.join(',');
				// if (postData.timeList.length) {
				// 	postData.timeList.forEach(item => {
				// 		if (item.time[0]) {
				// 			item.startTime = this.moment(item.time[0]).startOf('days').unix();
				// 		} else {
				// 			item.startTime = 0;
				// 		}
				// 		if (item.time[1]) {
				// 			item.endTime = this.moment(item.time[1]).endOf('days').unix();
				// 		} else {
				// 			item.endTime = 0;
				// 		}
        //     delete item.time;
				// 		if(item.cycles && item.cycles.length) {
				// 			item.cycles.forEach(subItem => {
        //         const startTime = this.moment(subItem.time[0]);
        //         const endTime = this.moment(subItem.time[1]);
        //         subItem.startTime = startTime.hour() * 3600 + startTime.minute() * 60;
        //         subItem.endTime = endTime.hour() * 3600 + endTime.minute() * 60;
        //         delete subItem.time;
				// 			})
				// 		}
				// 	})
				// }
        const timeList = [];
        if (postData.timeList.length) {
          postData.timeList.forEach(item => {
            if (item.time[0]) {
              item.startTime = this.moment(item.time[0]).startOf('days').unix();
            } else {
              item.startTime = 0;
            }
            if (item.time[1]) {
              item.endTime = this.moment(item.time[1]).endOf('days').unix();
            } else {
              item.endTime = 0;
            }
            delete item.time;
            let cycles = [];
            if(item.cycles && item.cycles.length) {
              item.cycles.forEach(subItem => {
                // 原始内容
                // const subItem = {
                //   weeks: [1,2],
                //   time: []
                // }
                if (!subItem.weeks.length) {
                  return;
                }
                if (!subItem.time || !subItem.time.length) {
                  // 由于未配置时间段, 所以直接push cycles 即可
                  cycles.push({
                    weeks: subItem.weeks
                  })
                  return
                }
                // 如果有限制时间段, 则需要处理时间段
                const startTime = this.moment(subItem.time[0]);
                const endTime = this.moment(subItem.time[1]);
                subItem.startTime = startTime.hour() * 3600 + startTime.minute() * 60;
                subItem.endTime = endTime.hour() * 3600 + endTime.minute() * 60;
                if (subItem.time[0] || subItem.time[1]) {
                  cycles.push({
                    weeks: subItem.weeks,
                    startTime: subItem.startTime,
                    endTime: subItem.endTime,
                  })
                }
                delete subItem.time;
              })
            }
            if (item.startTime && item.endTime) {
              const pushItem = {
                startTime: item.startTime,
                endTime: item.endTime,
              };
              if (cycles.length) {
                pushItem.cycles = cycles;
              }
              timeList.push(pushItem);
            }
          })
        }
        postData.timeList = timeList;
        // 需要先有日期,再有周期,再有时间段
        const showTimeList = [];
        if (postData.showTimeList.length) {
          postData.showTimeList.forEach(item => {
            if (item.time[0]) {
              item.startTime = this.moment(item.time[0]).startOf('days').unix();
            } else {
              item.startTime = 0;
            }
            if (item.time[1]) {
              item.endTime = this.moment(item.time[1]).endOf('days').unix();
            } else {
              item.endTime = 0;
            }
            delete item.time;
            let cycles = [];
            if(item.cycles && item.cycles.length) {
              item.cycles.forEach(subItem => {
                // 原始内容
                // const subItem = {
                //   weeks: [1,2],
                //   time: []
                // }
                if (!subItem.weeks.length) {
                  return;
                }
                if (!subItem.time || !subItem.time.length) {
                  // 由于未配置时间段, 所以直接push cycles 即可
                  cycles.push({
                    weeks: subItem.weeks
                  })
                  return
                }
                // 如果有限制时间段, 则需要处理时间段
                const startTime = this.moment(subItem.time[0]);
                const endTime = this.moment(subItem.time[1]);
                subItem.startTime = startTime.hour() * 3600 + startTime.minute() * 60;
                subItem.endTime = endTime.hour() * 3600 + endTime.minute() * 60;
                if (subItem.time[0] || subItem.time[1]) {
                  cycles.push({
                    weeks: subItem.weeks,
                    startTime: subItem.startTime,
                    endTime: subItem.endTime,
                  })
                }
                delete subItem.time;
              })
            }
            if (item.startTime && item.endTime) {
              const pushItem = {
                startTime: item.startTime,
                endTime: item.endTime,
              };
              if (cycles.length) {
                pushItem.cycles = cycles;
              }
              showTimeList.push(pushItem);
            }
          })
        }
        postData.showTimeList = showTimeList;
        if (postData.useLimit == 1) {
          postData.timeList = [];
        } else {
          postData.showTimeList = [];
        }
				this.loading = true;
				try {
					let ret;
					if (this.isEdit) {
						postData.id = this.id;
						ret = await updateRules(postData);
					} else {
						ret = await saveRules(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getRulesDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.movieList = ret.data.cinemaFilmList || [];
						this.hallDataList = ret.data.cinemaHallList || [];
						this.snackDataList = ret.data.snackShortList || [];
						this.goodsDataList = ret.data.goodsShortList || [];
						this.performDataList = ret.data.performShortList || [];
						ret.data.filmList = ret.data.filmList ? ret.data.filmList.split(',') : [];
						ret.data.hallList = ret.data.hallList ? ret.data.hallList.split(',') : [];
						ret.data.dimensionalList = ret.data.dimensionalList ? ret.data.dimensionalList.split(',').map(item => Number(item)) : [];
						ret.data.partitionList = ret.data.partitionList ? ret.data.partitionList.split(',').map(item => Number(item)) : [];
						ret.data.performList = ret.data.performList ? ret.data.performList.split(',').map(item => Number(item)) : [];
						ret.data.snackList = ret.data.snackList ? ret.data.snackList.split(',').map(item => Number(item)) : [];
						ret.data.goodsList = ret.data.goodsList ? ret.data.goodsList.split(',').map(item => Number(item)) : [];
            // ret.data.timeList = [{"cycles":[{"weeks":[7,1],"startTime":32400,"endTime":36000}],"startTime":1725811200,"endTime":1727711999}];
            if (ret.data.timeList && ret.data.timeList.length) {
              ret.data.timeList.forEach(item=>{
                item.time = [this.moment(item.startTime*1000), this.moment(item.endTime*1000)];
                if (item.cycles && item.cycles.length) {
                  item.cycles.forEach(subItem=>{
                    subItem.time = [];
                    if (subItem.endTime) {
                      subItem.time[1] = this.moment().startOf('day').add(subItem.endTime, 'seconds');
                      subItem.time[0] = this.moment().startOf('day').add(subItem.startTime, 'seconds');
                    }
                    delete subItem.startTime;
                    delete subItem.endTime;
                  })
                } else {
                  item.cycles = [
                    {
                      weeks: [],
                      time: []
                    }
                  ]
                }
                delete item.startTime;
                delete item.endTime;
              })
            } else {
              ret.data.timeList = [{
                time: [],
                cycles: [
                  {
                    weeks: [],
                    time: []
                  }
                ]
              }];
            }
            if (ret.data.showTimeList && ret.data.showTimeList.length) {
              ret.data.showTimeList.forEach(item=>{
                item.time = [this.moment(item.startTime*1000), this.moment(item.endTime*1000)];
                if (item.cycles && item.cycles.length) {
                  item.cycles.forEach(subItem=>{
                    subItem.time = [];
                    if (subItem.endTime) {
                      subItem.time[1] = this.moment().startOf('day').add(subItem.endTime, 'seconds');
                      subItem.time[0] = this.moment().startOf('day').add(subItem.startTime, 'seconds');
                    }
                    delete subItem.startTime;
                    delete subItem.endTime;
                  })
                } else {
                  item.cycles = [
                    {
                      weeks: [],
                      time: []
                    }
                  ]
                }
                delete item.startTime;
                delete item.endTime;
              })
            } else {
              ret.data.showTimeList = [{
                time: [],
                cycles: [
                  {
                    weeks: [],
                    time: []
                  }
                ]
              }];
            }
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaPartitionList() {
				this.loading = true;
				try {
					let ret = await getAllCinemaPartitionList({})
					this.loading = false;
					if (ret.code === 200) {
						this.partitionAllList = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onTypeChange() {
				this.modelRef.filmType = 1;
				this.modelRef.filmList = [];
				this.movieList = [];
				this.modelRef.hallType = 1;
				this.modelRef.hallList = [];
				this.hallDataList = [];
				this.modelRef.visualType = 1;
				this.modelRef.dimensionalList = [];
				this.modelRef.partitionType = 1;
				this.modelRef.partitionList = [];
				this.performDataList = [];
				this.modelRef.performType = 1;
				this.modelRef.performList = [];
				this.modelRef.performDataList = [];
				this.modelRef.snackType = 1;
				this.modelRef.snackList = [];
				this.modelRef.snackDataList = [];
				this.modelRef.goodsType = 1;
				this.modelRef.goodsList = [];
				this.modelRef.goodsDataList = [];
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.filmList.indexOf(item.id);
				this.modelRef.filmList.splice(i, 1);
				this.movieList.splice(index, 1);
			},
			onDeleteHall(item, index) {
				let i = this.modelRef.hallList.indexOf(item.id);
				this.modelRef.hallList.splice(i, 1);
				this.hallDataList.splice(index, 1);
			},
			onCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.dimensionalList = [1, 2, 3, 4, 5];
				} else {
					this.modelRef.dimensionalList = [];
				}
			},
			onPartitionCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.partitionList = this.partitionList.map(item => {
						return item.id;
					})
				} else {
					this.modelRef.partitionList = [];
				}
			},
			onDeletePerform(item, index) {
				let i = this.modelRef.performList.indexOf(item.id);
				this.modelRef.performList.splice(i, 1);
				this.performDataList.splice(index, 1);
			},
			onDeleteSnack(item, index) {
				let i = this.modelRef.snackList.indexOf(item.id);
				this.modelRef.snackList.splice(i, 1);
				this.snackDataList.splice(index, 1);
			},
			onDeleteGoods(item, index) {
				let i = this.modelRef.goodsList.indexOf(item.id);
				this.modelRef.goodsList.splice(i, 1);
				this.goodsDataList.splice(index, 1);
			},
			onAddSet(recordIndex) {
				if (this.isSee) return;
        this.modelRef.timeList.push({
          "startTime":'',
          "endTime":'',
          "cycles":[{
            weeks: [],
            startTime: this.moment('00:00', 'HH:mm'),
            endTime: this.moment('23:59', 'HH:mm')
          }]
        })
			},
			onDelSetting(recordIndex, index) {
        this.modelRef.timeList.splice(recordIndex, 1);
			},
      onAddSubSetting(recordIndex, index) {
        this.modelRef.timeList[recordIndex].cycles.push({
          weeks: [],
          time: []
        });
      },
      onDelSubSetting(recordIndex, index) {
        this.modelRef.timeList[recordIndex].cycles.splice(index, 1);
      },
      onAddSetShowTime(recordIndex) {
        if (this.isSee) return;
        this.modelRef.showTimeList.push({
          "startTime":'',
          "endTime":'',
          "cycles":[{
            weeks: [],
            startTime: this.moment('00:00', 'HH:mm'),
            endTime: this.moment('23:59', 'HH:mm')
          }]
        })
      },
      onDelSettingShowTime(recordIndex, index) {
        this.modelRef.showTimeList.splice(recordIndex, 1);
      },
      onAddSubSettingShowTime(recordIndex, index) {
        this.modelRef.showTimeList[recordIndex].cycles.push({
          weeks: [],
          time: []
        });
      },
      onDelSubSettingShowTime(recordIndex, index) {
        this.modelRef.showTimeList[recordIndex].cycles.splice(index, 1);
      },

		}
	}
</script>

<style scoped>
	.ui-min {
		display: inline-block;
		width: 40px;
		height: 31px;
		text-align: center;
		line-height: 31px;
		background-color: #d7d7d7;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}

	.ui-flex {
		display: flex;
		align-items: center;
	}
	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>
